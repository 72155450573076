var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"switch"},_vm._l((_vm.switchs),function(item,index){return _c('span',{key:index,staticClass:"switch-item",class:{ 'switch-active': _vm.switchIndex === index },on:{"click":function($event){return _vm.switchClick(index)}}},[_vm._v(_vm._s(item))])}),0),_c('div',{staticClass:"switch-info"},[(_vm.switchIndex === 0)?[_c('div',{staticClass:"switch-box"},[_c('div',{staticClass:"switch-line"},[_c('span',[_vm._v("姓名")]),_c('el-input',{attrs:{"placeholder":"请输入"}})],1),_c('div',{staticClass:"switch-line"},[_c('span',[_vm._v("性别")]),_c('el-input',{attrs:{"placeholder":"请输入"}})],1),_c('div',{staticClass:"switch-line"},[_c('span',[_vm._v("身份证号")]),_c('el-input',{attrs:{"placeholder":"请输入"}})],1),_c('div',{staticClass:"switch-line"},[_c('span',[_vm._v("参保类型")]),_c('el-input',{attrs:{"placeholder":"请输入"}})],1),_c('div',{staticClass:"switch-line"},[_c('span',[_vm._v("医保卡号")]),_c('el-input',{attrs:{"placeholder":"请输入"}})],1),_c('div',{staticClass:"switch-line"},[_c('span',[_vm._v("手机号")]),_c('el-input',{attrs:{"placeholder":"请输入"}})],1),_c('div',{staticClass:"switch-line switch-line1"},[_c('span',[_vm._v("家庭住址")]),_c('el-input',{staticClass:"input",attrs:{"placeholder":"请输入"}})],1)]),_vm._m(0),_c('div',{staticClass:"switch-box"},[_c('div',{staticClass:"switch-line switch-line1"},[_c('span',[_vm._v("申报病种")]),_c('el-input',{staticClass:"input",attrs:{"placeholder":"请输入"}})],1),_c('div',{staticClass:"switch-line switch-line1"},[_c('span',[_vm._v("认证机构")]),_c('el-input',{staticClass:"input",attrs:{"placeholder":"请输入"}})],1)])]:(_vm.switchIndex === 1)?[_vm._m(1),_c('img',{staticClass:"switch-img"}),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-title"},[_c('span',[_vm._v("申报信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-title switch-title1"},[_c('span',[_vm._v("身份证或医保卡")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-title switch-title1"},[_c('span',[_vm._v("病历资料和检查资料")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-text"},[_c('span',{staticClass:"icon"}),_c('span',[_vm._v("高血压")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-text switch-text1"},[_c('span',[_vm._v("要求上传的内容：1、出院记录；2、大病历；3、血尿常规，生化检查，心脏、颈部血管超声，心电图，头部CT或MRI;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-image"},[_c('img',{staticClass:"switch-img"}),_c('img',{staticClass:"switch-img"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-text switch-margin"},[_c('span',{staticClass:"icon"}),_c('span',[_vm._v("糖尿病")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-text switch-text1"},[_c('span',[_vm._v("要求上传的内容：1、出院记录；2、大病历；3、血尿常规，生化检查，心脏、颈部血管超声，心电图，头部CT或MRI;")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"switch-image"},[_c('img',{staticClass:"switch-img"}),_c('img',{staticClass:"switch-img"})])
}]

export { render, staticRenderFns }