<template>
  <div class="referral">
    <div class="head">
      <el-button @click="tabClick(0)" :class="{ blue: tabIndex === 0, color: tabIndex === 1 }">诊断管理</el-button>
      <el-button @click="tabClick(1)" :class="{ blue: tabIndex === 1, color: tabIndex === 0 }">预约管理</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="input">
      <div class="item">
        <span class="span">患者姓名</span>
        <el-input placeholder="请输入关键字">
        </el-input>
      </div>
      <div class="item">
        <span class="span">B超类型</span>
        <el-input placeholder="请输入关键字">
        </el-input>
      </div>
      <div class="item">
        <span class="span">上传机构</span>
        <el-input placeholder="请输入关键字">
        </el-input>
      </div>
      <el-button class="blue width">筛选</el-button>
    </div>
    <div class="swich" v-show="(tabIndex === 0)">
      <el-table :data="tableData" height="456" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="序号">
        </el-table-column>
        <el-table-column prop="date" label="姓名">
        </el-table-column>
        <el-table-column prop="date" label="性别">
        </el-table-column>
        <el-table-column prop="type" label="上次时间">
        </el-table-column>
        <el-table-column prop="type1" label="诊断时间">
        </el-table-column>
        <el-table-column prop="type2" label="B超类型">
        </el-table-column>
        <el-table-column prop="type" label="上传机构">
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <div class="flex" :class="{ flex1: scope.row.status === '1' }" v-if="scope.row.status === '1'">
              <span @click="openInfo">立即处理</span>
            </div>
            <div class="flex" v-else-if="scope.row.status !== '1'">
              <span @click="openSeeReport">查看报告</span>
              <span @click="openSeeMaxImg">查看B超</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
          background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
          :total="400">
        </el-pagination>
      </div>
    </div>
    <div class="swich" v-show="(tabIndex === 1)">
      <el-table :data="tableData" height="456" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="序号">
        </el-table-column>
        <el-table-column prop="date" label="姓名">
        </el-table-column>
        <el-table-column prop="date" label="性别">
        </el-table-column>
        <el-table-column prop="type" label="上次时间">
        </el-table-column>
        <el-table-column prop="type1" label="诊断时间">
        </el-table-column>
        <el-table-column prop="type2" label="B超类型">
        </el-table-column>
        <el-table-column prop="type" label="上传机构">
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <div class="flex flex1">
              <span v-if="scope.row.status === '1'" @click="openOrderReview">预约审核</span>
              <span v-else-if="scope.row.status !== '1'" @click="openSeeDetails">查看详情</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
          background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
          :total="400">
        </el-pagination>
      </div>
    </div>
    <!-- 立即处理 -->
    <Info v-if="showInfo" seeType="bc" @close="closeInfo"></Info>
    <!-- 查看报告 -->
    <SeeReport v-if="showSeeReport" reportType="bc" @close="closeSeeReport"></SeeReport>
    <!-- 查看图片 -->
    <SeeMaxImg v-if="showSeeMaxImg" imgType="bc" @close="closeSeeMaxImg"></SeeMaxImg>
    <!-- 预约审核 -->
    <OrderReview v-if="showOrderReview" orderType="bc" @close="closeOrderReview"></OrderReview>
    <!-- 查看详情 -->
    <SeeDetails v-if="showSeeDetails" seeType="bc" @close="closeSeeDetails"></SeeDetails>
  </div>
</template>

<script>
import Info from './info.vue'
import SeeReport from './seeReport.vue'
import SeeMaxImg from './seeMaxImg.vue'
import OrderReview from './orderReview.vue'
import SeeDetails from './seeDetails.vue'
export default {
  components: {
    Info,
    SeeReport,
    SeeMaxImg,
    OrderReview,
    SeeDetails
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '已结束'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      tabIndex: 0,
      showInfo: false,
      showSeeReport: false,
      showSeeMaxImg: false,
      showOrderReview: false,
      showSeeDetails: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openInfo () {
      this.showInfo = true
    },
    closeInfo () {
      this.showInfo = false
    },
    openSeeReport () {
      this.showSeeReport = true
    },
    closeSeeReport () {
      this.showSeeReport = false
    },
    openSeeMaxImg () {
      this.showSeeMaxImg = true
    },
    closeSeeMaxImg () {
      this.showSeeMaxImg = false
    },
    openOrderReview () {
      this.showOrderReview = true
    },
    closeOrderReview () {
      this.showOrderReview = false
    },
    openSeeDetails () {
      this.showSeeDetails = true
    },
    closeSeeDetails () {
      this.showSeeDetails = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 37px;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
    height: calc(100% - 200px);
  }

  .head,
  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    margin-bottom: 20px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      width: 152px;
      height: 38px;
      line-height: 38px;
      padding: 0;
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .blue {
      margin-right: 20px;
      background-color: #01EEFD;
    }

    .color {
      margin-right: 20px;
      background-color: #D5D5D5;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
          height: 38px;
        line-height: 38px;
        }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;
    }

    /deep/.el-input__inner {
      background-color: transparent;
      color: #fff !important;
      font-size: 18px;
      border: none;

    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    .item {
      display: flex;
      align-items: center;
      margin-right: 60px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 206px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
        line-height: 38px;
        }
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
