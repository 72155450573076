var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup"},[_vm._m(0),_c('div',{staticClass:"scroll"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"line"},[_c('span',[_vm._v(_vm._s(_vm.imgTitle))])]),_vm._m(7),_c('div',{staticClass:"see"},[_c('el-button',{on:{"click":_vm.openSeeMaxImg}},[_vm._v("查看大图")])],1),_vm._m(8),_vm._m(9)]),_c('div',{staticClass:"btns"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{staticClass:"color",on:{"click":_vm.close}},[_vm._v("确定")])],1),(_vm.showSeeMaxImg)?_c('SeeMaxImg',{on:{"close":_vm.closeSeeMaxImg}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("患者信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("申请信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex1"},[_c('span',[_vm._v("申请状态：审核通过")]),_c('span',[_vm._v("申请时间：2020-10-04 11：0019：09")]),_c('span',[_vm._v("申请机构：XXX卫生院")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("基本信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('span',{staticClass:"item"},[_vm._v("患者姓名：张三")]),_c('span',{staticClass:"item"},[_vm._v("姓别：男")]),_c('span',{staticClass:"item"},[_vm._v("年龄：44")]),_c('span',{staticClass:"item"},[_vm._v("手机号：130********")]),_c('span',{staticClass:"item"},[_vm._v("身份证号:130********1111")]),_c('span',{staticClass:"item"},[_vm._v("身高:174CM")]),_c('span',{staticClass:"item"},[_vm._v("体重:56kg")]),_c('span',{staticClass:"item"},[_vm._v("医保号：12937283282")]),_c('span',{staticClass:"item"},[_vm._v("主诉：主诉主诉主诉")]),_c('span',{staticClass:"item"},[_vm._v("既往史：高血压")]),_c('span',{staticClass:"item"},[_vm._v("家族史：父亲 高血压")]),_c('span',{staticClass:"item"},[_vm._v("家庭住址:河北省石家庄市XX镇")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('span',[_vm._v("检查信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('span',{staticClass:"item"},[_vm._v("检查单号：110")]),_c('span',{staticClass:"item"},[_vm._v("检查单号：110")]),_c('span',{staticClass:"item"},[_vm._v("门诊号：20529347")]),_c('span',{staticClass:"item"},[_vm._v("申请医生：胡晓峰")]),_c('span',{staticClass:"item"},[_vm._v("患者来源：门诊")]),_c('span',{staticClass:"item"},[_vm._v("检查项目：腹部B超")]),_c('span',{staticClass:"item"},[_vm._v("检查部位：肝、胆")]),_c('span',{staticClass:"item"},[_vm._v("检查医生：李四")]),_c('span',{staticClass:"item item1"},[_vm._v("检查时间：2020-10-04 11：0019：09")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex2"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"img"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textarea"},[_c('span',{staticClass:"width"},[_vm._v("诊断结论：")]),_c('div',{staticClass:"border"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"textarea"},[_c('span',{staticClass:"width"},[_vm._v("备注：")]),_c('div',{staticClass:"border"})])
}]

export { render, staticRenderFns }