<template>
  <div class="write">
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="head">
        <span>{{ title }}</span>
      </div>
      <div class="scroll">
        <div class="title">
        <span>患者信息</span>
      </div>
        <div class="line">
          <span>申请信息</span>
        </div>
        <div class="flex flex1">
          <span>申请状态：{{ sqzt }}</span>
          <span>申请时间：{{ sqsj }}</span>
          <span>申请机构：{{ sqjg }}</span>
        </div>
        <div class="line">
          <span>基本信息</span>
        </div>
        <div class="flex">
          <span class="item">患者姓名：{{ name }}</span>
          <span class="item">姓别：{{ xb }}</span>
          <span class="item">年龄：{{ age }}</span>
          <span class="item">手机号：{{ phone }}</span>
          <span class="item">身份证号:{{ sfzh }}</span>
          <span class="item">身高:{{ sg }}</span>
          <span class="item">体重:{{ tz }}</span>
          <span class="item">医保号：{{ ybh }}</span>
          <span class="item">主诉：{{ zs }}</span>
          <span class="item">既往史：{{ jws }}</span>
          <span class="item">家族史：{{ jzs }}</span>
          <span class="item">家庭住址:{{ address }}</span>
        </div>
        <div class="line">
          <span>检查信息</span>
        </div>
        <div class="flex">
          <span class="item">检查单号：{{ jcdh }}</span>
          <span class="item">门诊号：{{ mzh }}</span>
          <span class="item">申请医生：{{ sqys }}</span>
          <span class="item">患者来源：{{ hzly }}</span>
          <span class="item">检查项目：{{ jcxm }}</span>
          <span class="item">检查部位：{{ jcbw }}</span>
          <span class="item">检查医生：{{ jcys }}</span>
          <span class="item item1">检查时间：{{ jcsj }}</span>
        </div>
        <div class="line">
          <span>影像图</span>
        </div>
        <div class="flex flex2">
          <div class="img">{{ imageTwo }}</div>
          <div class="img">{{ imageThree }}</div>
        </div>
        <div class="see">
          <el-button @click="openSeeMaxImg">查看大图</el-button>
        </div>
        <div class="radio">
          <span>审核</span>
          <el-radio-group v-model="radio">
            <el-radio label="0">通过</el-radio>
            <el-radio label="1">驳回</el-radio>
          </el-radio-group>
        </div>
        <div class="textarea">
          <span class="width">诊断结论：</span>
          <div class="border"></div>
        </div>
      </div>
      <div class="btns">
        <el-button>取消</el-button>
        <el-button class="color">确定</el-button>
      </div>
    </div>
    <SeeMaxImg v-if="showSeeMaxImg" @close="closeSeeMaxImg"></SeeMaxImg>
  </div>
</template>

<script>
import SeeMaxImg from './seeMaxImg.vue'
export default {
  props: {
    orderType: {
      type: String,
      default: 'bc'
    }
  },
  components: {
    SeeMaxImg
  },
  data () {
    return {
      radio: '0',
      showSeeMaxImg: false
    }
  },
  computed: {
    title () {
      if (this.orderType === 'bc') {
        return 'XXX卫生院腹部超声诊断预约单'
      } else if (this.orderType === 'xd') {
        return 'XXX卫生院心电诊断预约单'
      } else {
        return 'XXX卫生院影像诊断预约单'
      }
    }
  },
  methods: {
    openSeeMaxImg () {
      this.showSeeMaxImg = true
    },
    closeSeeMaxImg () {
      this.showSeeMaxImg = false
    },
    closePopup () {
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 .75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .content {
    position: relative;
    width: 100%;
    height: 10.25rem;
    margin-top: .125rem;
    padding: .25rem .8125rem;
    background-image: url('../img/ssjk/background5.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .2625rem;
      right: .4125rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .title {
      height: .375rem;
      line-height: .375rem;
      color: #fff;
      font-size: .25rem;
    }

    .head {
      width: 100%;
      height: .625rem;
      line-height: .625rem;
      text-align: center;
      color: #fff;
      font-size: .3rem;
      font-weight: bold;
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 2rem);
      padding: 0 .125rem;
      padding-bottom: .5rem;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .line {
        width: calc(100% - .225rem);
        height: .55rem;
        line-height: .55rem;
        margin-top: .125rem;
        margin-left: .225rem;
        padding: 0 .225rem;
        box-sizing: border-box;
        color: #fff;
        font-size: .225rem;
        border-bottom: .0125rem solid #fff;
      }

      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 .475rem;
        box-sizing: border-box;
        color: #fff;
        font-size: .2rem;

        span {
          margin-top: .25rem;
        }

        .item {
          width: 16%;
        }

        .item1 {
          width: 66.5%;
        }

        .img {
          width: 2.475rem;
          height: 2.475rem;
          margin-top: .25rem;
          background-color: #000;
        }

        .img:nth-child(2) {
          margin-left: 1.5875rem;
        }
      }

      .flex1 {
        width: 60%;
      }

      .flex2 {
        justify-content: center;
      }

      .see {
        display: flex;
        justify-content: center;
        width: 100%;
        height: .475rem;
        margin-top: .55rem;

        .el-button {
          width: 1.575rem;
          height: .475rem;
          line-height: .475rem;
          padding: 0;
          background: linear-gradient(101deg, #0176CC 0%, #02447A 100%);
          color: #fff;
          font-size: .225rem;
          border: none;
          border-radius: 0;
        }
      }

      .radio {
        display: flex;
        align-items: center;
        width: 100%;
        height: .375rem;
        padding: 0 .225rem;
        color: #fff;
        font-size: .2rem;
        box-sizing: border-box;

        .el-radio-group {
          display: flex;
          align-items: center;
          margin-left: .125rem;
          margin-top: .0625rem;
        }

        /deep/ .el-radio__label {
          color: #fff;
        }

        /deep/ .el-radio__input.is-checked .el-radio__inner {
          background-color: #01EEFD;
          border: none;
        }

        /deep/.el-radio__inner::after {
          content: '✓';
          width: auto;
          height: auto;
          background-color: #01EEFD;
          color: #fff;
          font-size: .15rem;
        }
      }

      .textarea {
        display: flex;
        width: 100%;
        height: .825rem;
        margin-top: .5rem;
        padding: 0 .225rem;
        box-sizing: border-box;
        color: #fff;
        font-size: .2rem;

        .width {
          width: 1.125rem;
        }

        .border {
          width: 12.2375rem;
          height: .825rem;
          border: .025rem #fff solid;
          box-sizing: border-box;
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 16%;
      height: .75rem;
      margin: 0 auto;

      .el-button {
        width: .875rem;
        height: .325rem;
        line-height: .325rem;
        text-align: center;
        background-color: #33465C;
        color: #fff;
        font-size: .175rem;
        border: 0;
        padding: 0;
        border-radius: 0;
      }

      .color {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
