<template>
  <div class="write">
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="title">
        <span>{{ title }}</span>
      </div>
      <el-select v-model="value" placeholder="获取到视频源设备" @change="change" v-if="imgType === 'bc'">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <video class="img" ref="video" autoplay></video>
      <div class="imgs">
        <!-- <div class="sort"></div> -->
        <div id="localStream" class="sort" @click="handleJoinRoom('30101000')"></div>
        <!-- <div class="sort"></div> -->
        <div class="sort" @click="handleJoinRoom('30102000')">
          <div v-for="item in remoteStreamList"
          :key="item.getUserId()"
          :id="item.getUserId()" class="sort"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import TRTC from 'trtc-js-sdk'
import { mapState } from 'vuex'
export default {
  props: {
    imgType: {
      type: String,
      default: 'bc'
    }
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '外接摄像头'
      }, {
        value: '2',
        label: '设备本身的摄像头'
      }, {
        value: '3',
        label: '采集卡'
      }],
      value: '外接摄像头',

      sdkAppId: 1400553134,
      secretKey: '6de8718a2f6fb064c893bc6d28e058240b3d6c3e5b9289c5c61cb78e6aa2967a',
      userId: '30101000',
      roomId: 123455,
      cameraId: '',
      microphoneId: '',
      inviteUserSig: '',

      logList: [],
      inviteLink: '',
      showCopiedTip: false,

      client: null,
      localStream: null,
      remoteStreamList: [],
      isJoining: false,
      isJoined: false,
      isPublishing: false,
      isPublished: false,
      isMutedVideo: false,
      isMutedAudio: false,
      isPlayingLocalStream: false
    }
  },
  computed: {
    ...mapState(['loginInfo']),
    title () {
      if (this.imgType === 'bc') {
        return '腹部超声'
      } else if (this.imgType === 'xd') {
        return '心电图报告'
      } else {
        return '胸部平扫CT影像'
      }
    }
  },
  mounted () {
    if (this.title === '腹部超声') {
      this.getCameraAccess()
    }
  },
  beforeDestroy () {
    this.leave()
    this.$refs.video.srcObject.getTracks()[0].stop()
  },
  methods: {
    getCameraAccess () {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.$refs.video.srcObject = stream
        })
        .catch(error => {
          console.error('获取摄像头访问权限失败：', error)
        })
    },
    change (e) {
      console.log(e)
      this.$refs.video.srcObject.getTracks()[0].stop()
      if (e === '3') return
      setTimeout(() => {
        this.getCameraAccess()
      }, 500)
    },

    // 点击【Join Room】按钮
    async handleJoinRoom (userId) {
      this.userId = userId
      if (userId === '30101000') {
        this.userSig = 'eJw1jMsKgzAUBf8l6yI3mocKXVhXBUGwtbhVTMvtyxBDrJT*e4O2nNWZgXmTY3EInDIkJWEAZLN87NXT4hkXHAH1g78b*1urNfYkpQyA84hGbDUWH8pTCVTGgiZipeql0XgugMUAvwZefNiavJ50vRuysnKnqr6ztuN55*ayaaYhnPeZunZNkWg5bsnnC8CUMVQ_'
      } else {
        this.userSig = 'eJw1zF0LgjAYhuH-suOQd5tuQ*ggEKwQDLQOOpM29cU*hs4Io--e0Dp8rgfuNymzIniansSEBUBW80Zt7g5rnJkDBQbw-wbdVdaiJjENAaKIUx4uj8Ob8SqBSiW4Youal8Xeu4BQAfwa2PhwVlcF8s0lv*724jSeO9ea0aptfUjSaZA5E6LFx3FKymZNPl*w6zDk'
      }
      await this.initClient()
      await this.join()
      await this.initLocalStream()
      await this.playLocalStream()
      await this.publish()
    },

    // 点击【Publish】按钮
    async handlePublish () {
      await this.publish()
    },

    // 点击【Unpublish】按钮
    async handleUnpublish () {
      await this.unPublish()
    },

    // 点击【Leave Room】按钮
    async handleLeave () {
      await this.leave()
    },

    // 初始化客户端
    async initClient () {
      this.client = TRTC.createClient({
        mode: 'rtc',
        sdkAppId: this.sdkAppId,
        userId: this.userId,
        userSig: this.userSig
      })
      this.handleClientEvents()
    },

    async initLocalStream () {
      this.localStream = TRTC.createStream({
        audio: true,
        video: true,
        userId: this.userId,
        cameraId: this.cameraId,
        microphoneId: this.microphoneId
      })
      try {
        await this.localStream.initialize()
      } catch (error) {
        this.localStream = null
        throw error
      }
    },

    playLocalStream () {
      this.localStream
        .play('localStream')
        .then(() => {
          this.isPlayingLocalStream = true
        })
        .catch(error => {
          console.log(error)
        })
    },

    destroyLocalStream () {
      this.localStream && this.localStream.stop()
      this.localStream && this.localStream.close()
      this.localStream = null
      this.isPlayingLocalStream = false
    },

    playRemoteStream (remoteStream, element) {
      if (
        remoteStream.getType() === 'main' &&
        remoteStream.getUserId().indexOf('share') >= 0
      ) {
        remoteStream.play(element, { objectFit: 'contain' }).catch()
      } else {
        remoteStream.play(element).catch()
      }
    },

    async join () {
      if (this.isJoining || this.isJoined) {
        return
      }
      this.isJoining = true
      !this.client && (await this.initClient())
      try {
        await this.client.join({ roomId: this.roomId })
        this.isJoining = false
        this.isJoined = true
        this.startGetAudioLevel()
      } catch (error) {
        this.isJoining = false
        console.error('join room failed', error)
        throw error
      }
    },

    async publish () {
      if (!this.isJoined || this.isPublishing || this.isPublished) {
        return
      }
      this.isPublishing = true
      try {
        await this.client.publish(this.localStream)
        this.isPublishing = false
        this.isPublished = true
      } catch (error) {
        this.isPublishing = false
        console.error('publish localStream failed', error)
        throw error
      }
    },

    async unPublish () {
      if (!this.isPublished || this.isUnPublishing) {
        return
      }
      this.isUnPublishing = true
      try {
        await this.client.unpublish(this.localStream)
        this.isUnPublishing = false
        this.isPublished = false
      } catch (error) {
        this.isUnPublishing = false
        console.error('unpublish localStream failed', error)
        throw error
      }
    },

    async subscribe (remoteStream, config = { audio: true, video: true }) {
      try {
        await this.client.subscribe(remoteStream, {
          audio: config.audio === 'undefined' ? true : config.audio,
          video: config.video === 'undefined' ? true : config.video
        })
      } catch (error) {
        console.error(
          `subscribe ${remoteStream.getUserId()} with audio: ${
            config.audio
          } video: ${config.video} error`,
          error
        )
      }
    },

    async unSubscribe (remoteStream) {
      try {
        await this.client.unsubscribe(remoteStream)
      } catch (error) {
        console.error(`unsubscribe ${remoteStream.getUserId()} error`, error)
      }
    },

    async leave () {
      if (!this.isJoined || this.isLeaving) {
        return
      }
      this.isLeaving = true
      this.stopGetAudioLevel()
      this.isPublished && (await this.unPublish())
      this.localStream && this.destroyLocalStream()

      try {
        await this.client.leave()
        this.isLeaving = false
        this.isJoined = false
      } catch (error) {
        this.isLeaving = false
        console.error('leave room error', error)
        throw error
      }
    },

    muteVideo () {
      if (this.localStream) {
        this.localStream.muteVideo()
        this.isMutedVideo = true
      }
    },

    muteAudio () {
      if (this.localStream) {
        this.localStream.muteAudio()
        this.isMutedAudio = true
      }
    },

    unmuteVideo () {
      if (this.localStream) {
        this.localStream.unmuteVideo()
        this.isMutedVideo = false
      }
    },

    unmuteAudio () {
      if (this.localStream) {
        this.localStream.unmuteAudio()
        this.isMutedAudio = false
      }
    },

    startGetAudioLevel () {
      this.client.on('audio-volume', event => {
        event.result.forEach(({ userId, audioVolume }) => {
          if (audioVolume > 2) {
            console.log(`user: ${userId} is speaking, audioVolume: ${audioVolume}`
            )
          }
        })
      })
      this.client.enableAudioVolumeEvaluation(200)
    },

    stopGetAudioLevel () {
      this.client && this.client.enableAudioVolumeEvaluation(-1)
    },

    // 监听
    handleClientEvents () {
      this.client.on('error', error => {
        console.error(error)
      })
      this.client.on('client-banned', async event => {
        console.warn(`client has been banned for ${event.reason}`)
        this.isPublished = false
        this.localStream = null
        await this.leave()
      })
      this.client.on('peer-join', event => {
        const { userId } = event
        console.log(`peer-join ${userId}`, event)
      })
      this.client.on('peer-leave', event => {
        const { userId } = event
        console.log(`peer-leave ${userId}`, event)
      })
      this.client.on('stream-added', event => {
        const { stream: remoteStream } = event
        const remoteUserId = remoteStream.getUserId()
        if (remoteUserId === `share_${this.userId}`) {
          this.unSubscribe(remoteStream)
        } else {
          console.log(
            `remote stream added: [${remoteUserId}] type: ${remoteStream.getType()}`
          )
          this.subscribe(remoteStream)
        }
      })
      this.client.on('stream-subscribed', event => {
        const { stream: remoteStream } = event
        const remoteUserId = remoteStream.getUserId()
        console.log('stream-subscribed userId: ', remoteUserId)
        this.remoteStreamList.push(remoteStream)
        this.$nextTick(() => {
          this.playRemoteStream(remoteStream, remoteUserId)
        })
      })
      this.client.on('stream-removed', event => {
        const { stream: remoteStream } = event
        remoteStream.stop()
        const index = this.remoteStreamList.indexOf(remoteStream)
        if (index >= 0) {
          this.remoteStreamList.splice(index, 1)
        }
        console.log(
          `stream-removed userId: ${remoteStream.getUserId()} type: ${remoteStream.getType()}`
        )
      })

      this.client.on('stream-updated', event => {
        const { stream: remoteStream } = event
        console.log(`type: ${remoteStream.getType()} stream-updated hasAudio: ${remoteStream.hasAudio()} hasVideo: ${remoteStream.hasVideo()}`)
      })

      this.client.on('mute-audio', event => {
        const { userId } = event
        console.log(`${userId} mute audio`)
      })
      this.client.on('unmute-audio', event => {
        const { userId } = event
        console.log(`${userId} unmute audio`)
      })
      this.client.on('mute-video', event => {
        const { userId } = event
        console.log(`${userId} mute video`)
      })
      this.client.on('unmute-video', event => {
        const { userId } = event
        console.log(`${userId} unmute video`)
      })

      this.client.on('connection-state-changed', event => {
        console.log(`RtcClient state changed to ${event.state} from ${event.prevState}`)
      })

      this.client.on('network-quality', event => {
        const { uplinkNetworkQuality, downlinkNetworkQuality } = event
        console.log(`network-quality uplinkNetworkQuality: ${uplinkNetworkQuality}, downlinkNetworkQuality: ${downlinkNetworkQuality}`)
      })
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 60px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 820px;
    margin-top: 10px;
    padding: 20px 65px;
    background-image: url('../img/ssjk/background5.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 21px;
      right: 33px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    .img {
      width: 428px;
      height: 340px;
      margin: 0 auto;
      background-color: #000;
      margin: 10px auto;
    }

    .imgs {
      display: flex;
      justify-content: space-between;
      width: 532px;
      height: 208px;
      margin: 20px auto 0;

      .sort {
        width: 208px;
        height: 208px;
        background-color: #000;
      }
    }
  }
}
</style>
