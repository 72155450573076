<template>
  <div class="tab-content">
    <div class="switch">
      <span class="switch-item" :class="{ 'switch-active': switchIndex === index }" v-for="(item, index) in switchs"
        :key="index" @click="switchClick(index)">{{ item }}</span>
    </div>
    <div class="switch-info">
      <template v-if="switchIndex === 0">
        <div class="switch-box">
        <div class="switch-line">
          <span>姓名</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="switch-line">
          <span>性别</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="switch-line">
          <span>身份证号</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="switch-line">
          <span>参保类型</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="switch-line">
          <span>医保卡号</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="switch-line">
          <span>手机号</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="switch-line switch-line1">
          <span>家庭住址</span>
          <el-input class="input" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="switch-title">
        <span>申报信息</span>
      </div>
      <div class="switch-box">
        <div class="switch-line switch-line1">
          <span>申报病种</span>
          <el-input class="input" placeholder="请输入"></el-input>
        </div>
        <div class="switch-line switch-line1">
          <span>认证机构</span>
          <el-input class="input" placeholder="请输入"></el-input>
        </div>
      </div>
      </template>
      <template v-else-if="switchIndex === 1">
        <div class="switch-title switch-title1">
          <span>身份证或医保卡</span>
        </div>
        <img class="switch-img">
        <div class="switch-title switch-title1">
          <span>病历资料和检查资料</span>
        </div>
        <div class="switch-text">
          <span class="icon"></span>
          <span>高血压</span>
        </div>
        <div class="switch-text switch-text1">
          <span>要求上传的内容：1、出院记录；2、大病历；3、血尿常规，生化检查，心脏、颈部血管超声，心电图，头部CT或MRI;</span>
        </div>
        <div class="switch-image">
          <img class="switch-img">
          <img class="switch-img">
        </div>
        <div class="switch-text switch-margin">
          <span class="icon"></span>
          <span>糖尿病</span>
        </div>
        <div class="switch-text switch-text1">
          <span>要求上传的内容：1、出院记录；2、大病历；3、血尿常规，生化检查，心脏、颈部血管超声，心电图，头部CT或MRI;</span>
        </div>
        <div class="switch-image">
          <img class="switch-img">
          <img class="switch-img">
        </div>
      </template>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['基本信息', '材料证明'])
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  width: 100%;
  height: 100%;

  .switch {
    display: flex;
    align-items: center;
    height: 50px;
    box-sizing: border-box;

    .switch-item {
      margin-right: 40px;
      border-bottom: 4px solid transparent;
      box-sizing: border-box;
      color: #FFFFFF;
      font-size: 18px;
      cursor: pointer;
    }

    .switch-active {
      color: #03BCD7;
      border-bottom: 4px solid #03BCD7;
    }
  }

  .switch-info {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 20px;
    padding: 0 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .switch-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;

      .switch-line {
        display: flex;
        align-items: center;
        width: 33%;
        margin-bottom: 20px;
        color: #01EEFD;
        font-size: 18px;

        .el-input {
          width: 200px;
          margin-left: 10px;
        }

        .input {
          width: 490px;
        }
      }

      .switch-line1 {
        width: 100%;
      }
    }

    .switch-title {
      height: 35px;
      line-height: 35px;
      margin-bottom: 10px;
      color: #fff;
      font-size: 18px;
    }

    .switch-title1 {
      color: #01EEFD;
    }

    .switch-img {
      width: 240px;
      height: 130px;
      margin-right: 44px;
      background-color: #fff;
    }

    .switch-text {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: #01EEFD;
      font-size: 16px;

      .icon {
        width: 22px;
        height: 22px;
        margin-right: 6px;
        background-color: #00DFFF;
        border-radius: 50%;
        border: 2px solid #fff;
        box-sizing: border-box;
      }
    }

    .switch-text1 {
      color: #01EEFD;
      font-size: 14px;
    }

    .switch-image {
      width: 100%;
      height: 130px;
    }

    .switch-margin {
      margin-top: 10px;
    }
  }
}
</style>
