<template>
  <div class="tab-content">
    <div class="switch-info">
      <div class="switch-title switch-title1">
        <span>检查报告</span>
      </div>
      <div class="switch-text">
        <span class="icon">1</span>
        <span>高血压</span>
      </div>
      <div class="switch-text switch-text1">
        <span>要求上传的内容：1、出院记录；2、大病历；3、血尿常规，生化检查，心脏、颈部血管超声，心电图，头部CT或MRI;</span>
      </div>
      <div class="switch-table">
        <div class="switch-tr border-bottom">
          <div class="switch-td switch-td1 border-right">
            <span>检查报告</span>
          </div>
          <div class="switch-td">
            <img class="file">
            <span class="margin-right">心电图.jpg</span>
            <img class="file">
            <span class="margin-right">血常规.jpg</span>
            <img class="file">
            <span>xxx检查报告.pdf</span>
          </div>
        </div>
        <div class="switch-tr">
          <div class="switch-td switch-td1 border-right">
            <span>检查时间</span>
          </div>
          <div class="switch-td">
            <span>2018-09-02</span>
          </div>
        </div>
      </div>
      <div class="switch-text switch-margin">
        <span class="icon">2</span>
        <span>糖尿病</span>
      </div>
      <div class="switch-text switch-text1">
        <span>要求上传的内容：1、出院记录；2、大病历；3、血尿常规，生化检查，心脏、颈部血管超声，心电图，头部CT或MRI;</span>
      </div>
      <div class="switch-table">
        <div class="switch-tr border-bottom">
          <div class="switch-td switch-td1 border-right">
            <span>检查报告</span>
          </div>
          <div class="switch-td">
            <img class="file">
            <span class="margin-right">心电图.jpg</span>
            <img class="file">
            <span class="margin-right">血常规.jpg</span>
            <img class="file">
            <span>xxx检查报告.pdf</span>
          </div>
        </div>
        <div class="switch-tr">
          <div class="switch-td switch-td1 border-right">
            <span>检查时间</span>
          </div>
          <div class="switch-td">
            <span>2018-09-02</span>
          </div>
        </div>
      </div>

      <div class="switch-title switch-title1">
        <span>检查报告</span>
      </div>

      <div class="switch-table">
        <div class="switch-tr border-bottom">
          <div class="switch-td switch-td1 switch-height border-right">
            <span>检查意见</span>
          </div>
          <div class="switch-td switch-td2">
            <div class="switch-tr border-bottom">
              <div class="switch-td switch-td1 border-right">
                <span>高血压</span>
              </div>
              <div class="switch-td switch-td1 switch-td3">
                <span>符合</span>
              </div>
            </div>
            <div class="switch-tr">
              <div class="switch-td switch-td1 border-right">
                <span>高血压</span>
              </div>
              <div class="switch-td switch-td1 switch-td3">
                <span>符合</span>
              </div>
            </div>
          </div>
        </div>
        <div class="switch-tr border-bottom">
          <div class="switch-td switch-td1 switch-height border-right">
            <span>检查意见</span>
          </div>
          <div class="switch-td">
            <span>高血压、糖尿病均符合病种鉴定标准</span>
          </div>
        </div>
        <div class="switch-tr">
          <div class="switch-td switch-td1 border-right">
            <span>主审医师</span>
          </div>
          <div class="switch-td border-right switch-flex">
            <span>张医生</span>
          </div>
          <div class="switch-td switch-td1 border-right">
            <span>操作时间</span>
          </div>
          <div class="switch-td switch-flex">
            <span>2018-09-02 12:00:05</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      switchIndex: 1,
      switchs: Object.freeze(['基本信息', '材料证明'])
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  width: 100%;
  height: 100%;

  .switch-info {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 20px);
    padding: 0 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .switch-title {
      height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
      color: #fff;
      font-size: 18px;
    }

    .switch-title1 {
      color: #01EEFD;
    }

    .switch-text {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: #01EEFD;
      font-size: 16px;

      .icon {
        width: 22px;
        height: 22px;
        margin-right: 6px;
        text-align: center;
        background-color: #00DFFF;
        border-radius: 50%;
        border: 2px solid #fff;
        box-sizing: border-box;
        color: #fff;
        font-size: 14px;
      }
    }

    .switch-text1 {
      color: #01EEFD;
      font-size: 14px;
    }

    .switch-margin {
      margin-top: 10px;
    }

    .switch-table {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      border: 1px solid #03BCD7;
      box-sizing: border-box;

      .switch-tr {
        display: flex;
        align-items: center;
        width: 100%;

        .switch-td {
          display: flex;
          align-items: center;
          height: 35px;
          padding: 0 5px;
          color: #01EEFD;
          font-size: 14px;
          box-sizing: border-box;
        }

        .switch-td1 {
          width: 90px;
          justify-content: center;
        }

        .switch-td2 {
          flex-direction: column;
          align-items: flex-start;
          width: calc(100% - 90px);
          height: 100%;
          padding: 0;
        }

        .switch-td3 {
          width: calc(100% - 90px);
          justify-content: flex-start;
        }

        .switch-height {
          height: 70px;
        }

        .switch-flex {
          flex: 1;
        }

        .file {
          width: 40px;
          height: 20px;
          margin-right: 2px;
          background-color: #fff;
        }
      }

      .switch-tr1 {
        justify-content: flex-start;
      }

      .border-right {
        border-right: 1px solid #03BCD7;
        box-sizing: border-box;
      }

      .border-bottom {
        border-bottom: 1px solid #03BCD7;
        box-sizing: border-box;
      }

      .margin-right {
        margin-right: 20px;
      }
    }
  }
}
</style>
