<template>
  <div class="popup">
    <div class="title">
      <span>患者信息</span>
    </div>
    <div class="scroll">
      <div class="line">
        <span>申请信息</span>
      </div>
      <div class="flex flex1">
        <span>申请状态：审核通过</span>
        <span>申请时间：2020-10-04 11：0019：09</span>
        <span>申请机构：XXX卫生院</span>
      </div>
      <div class="line">
        <span>基本信息</span>
      </div>
      <div class="flex">
        <span class="item">患者姓名：张三</span>
        <span class="item">姓别：男</span>
        <span class="item">年龄：44</span>
        <span class="item">手机号：130********</span>
        <span class="item">身份证号:130********1111</span>
        <span class="item">身高:174CM</span>
        <span class="item">体重:56kg</span>
        <span class="item">医保号：12937283282</span>
        <span class="item">主诉：主诉主诉主诉</span>
        <span class="item">既往史：高血压</span>
        <span class="item">家族史：父亲 高血压</span>
        <span class="item">家庭住址:河北省石家庄市XX镇</span>
      </div>
      <div class="line">
        <span>检查信息</span>
      </div>
      <div class="flex">
        <span class="item">检查单号：110</span>
        <span class="item">检查单号：110</span>
        <span class="item">门诊号：20529347</span>
        <span class="item">申请医生：胡晓峰</span>
        <span class="item">患者来源：门诊</span>
        <span class="item">检查项目：腹部B超</span>
        <span class="item">检查部位：肝、胆</span>
        <span class="item">检查医生：李四</span>
        <span class="item item1">检查时间：2020-10-04 11：0019：09</span>
      </div>
      <div class="line">
        <span>{{ imgTitle }}</span>
      </div>
      <div class="flex flex2">
        <div class="img"></div>
        <div class="img"></div>
      </div>
      <div class="see">
        <el-button @click="openSeeMaxImg">查看大图</el-button>
      </div>
      <div class="textarea">
        <span class="width">诊断结论：</span>
        <div class="border"></div>
      </div>
      <div class="textarea">
        <span class="width">备注：</span>
        <div class="border"></div>
      </div>
    </div>
    <div class="btns">
      <el-button @click="close">取消</el-button>
      <el-button @click="close" class="color">确定</el-button>
    </div>
    <SeeMaxImg v-if="showSeeMaxImg" @close="closeSeeMaxImg"></SeeMaxImg>
  </div>
</template>

<script>
import SeeMaxImg from './seeMaxImg.vue'
export default {
  props: {
    seeType: {
      type: String,
      default: 'bc'
    }
  },
  components: {
    SeeMaxImg
  },
  computed: {
    imgTitle () {
      if (this.seeType === 'bc') {
        return '影像图'
      } else if (this.seeType === 'xd') {
        return '心电图'
      } else {
        return '心电图'
      }
    }
  },
  data () {
    return {
      showSeeMaxImg: false
    }
  },
  methods: {
    openSeeMaxImg () {
      this.showSeeMaxImg = true
    },
    closeSeeMaxImg () {
      this.showSeeMaxImg = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #09294C;

  .title {
    height: 30px;
    line-height: 30px;
    color: #01EEFD;
    font-size: 20px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 80px);
    padding: 0 24px;
    padding-bottom: 40px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .line {
      width: 100%;
      height: 44px;
      line-height: 44px;
      margin-top: 10px;
      padding: 0 18px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
      border-bottom: 1px solid #01EEFD;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 20px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 16px;

      span {
        margin-top: 20px;
      }

      .item {
        width: 16%;
      }

      .item1 {
        width: 66.5%;
      }

      .img {
        width: 198px;
        height: 198px;
        margin-top: 20px;
        background-color: #000;
      }

      .img:nth-child(2) {
        margin-left: 127px;
      }
    }

    .flex1 {
      width: 60%;
    }

    .flex2 {
      justify-content: center;
    }

    .see {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 38px;
      margin-top: 44px;

      .el-button {
        width: 126px;
        height: 38px;
        line-height: 38px;
        padding: 0;
        background: linear-gradient(101deg, #0176CC 0%, #02447A 100%);
        color: #fff;
        font-size: 18px;
        border: none;
        border-radius: 0;
      }
    }

    .textarea {
      display: flex;
      width: 100%;
      height: 66px;
      margin-top: 40px;
      padding: 0 18px;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 16px;

      .width {
        width: 90px;
      }

      .border {
        width: 979px;
        height: 66px;
        border: 2px #01EEFD solid;
        box-sizing: border-box;
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;

    .el-button {
      width: 70px;
      height: 26px;
      line-height: 26px;
      margin-right: 50px;
      text-align: center;
      background-color: #33465C;
      color: #fff;
      font-size: 14px;
      border: 0;
      padding: 0;
      border-radius: 0;
    }

    .color {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
