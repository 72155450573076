<template>
  <div class="report">
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="title">
        <span>{{ reportTitle }}</span>
      </div>
      <div class="flex">
        <span>患者姓名：{{ name }}</span>
        <span>姓别：{{ xb }}</span>
        <span>年龄：{{ age }}</span>
        <span>手机号：{{ phone }}</span>
        <span class="span">身份证号:{{ sfzh }}</span>
        <span>门诊号：{{ mzh }}</span>
        <span>申请科室：{{ sqks }}</span>
        <span>申请医生：{{ sqys }}</span>
        <span class="span1">患者来源：{{ hzly }}</span>
      </div>
      <div class="flex">
        <span>检查单号：{{ jcdh }}</span>
        <span>检查项目：{{ jcxm }}</span>
        <span>检查部位：{{ jcbw }}</span>
        <span>检查医生：{{ jcys }}</span>
        <span class="span">检查时间：{{ jcsj }}</span>
      </div>
      <div class="imgs" v-if="reportType === 'bc'">
        <div class="img"></div>
        <div class="img"></div>
      </div>
      <div class="info" v-if="reportType === 'bc'">
        <span class="info-title">诊断结论：</span>
        <span>超声所见：</span>
        <span>{{ cssj }}</span>
        <span class="info-title">影像诊断：</span>
        <span>超声所见：</span>
        <span>{{ csyxzd }}</span>
      </div>
      <div class="info" v-else-if="reportType === 'yx'">
        <span class="info-title">诊断结论：</span>
        <span>心电图所见：</span>
        <span>{{ xdtsj }}</span>
        <span class="info-title">影像诊断：</span>
        <span>{{ xdyxzd }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reportType: {
      type: String,
      default: 'bc'
    }
  },
  data () {
    return {}
  },
  computed: {
    reportTitle () {
      if (this.reportType === 'bc') {
        return 'XXX卫生院腹部超声报告'
      } else if (this.reportType === 'xd') {
        return 'XXX卫生院心电图报告'
      } else {
        return '影像诊断报告（CT）'
      }
    }
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.report {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 1.25rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .content {
    position: relative;
    width: 100%;
    height: 10.25rem;
    margin-top: .125rem;
    padding: .25rem .8125rem;
    background-image: url('../img/ssjk/background5.png');
    background-size: 100% 100%;
    box-sizing: border-box;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    .close {
      position: absolute;
      top: .2625rem;
      right: .3625rem;
      color: #fff;
      font-size: .3rem;
      cursor: pointer;
    }

    .title {
      width: 100%;
      height: .625rem;
      line-height: .625rem;
      text-align: center;
      color: #fff;
      font-size: .3rem;
      font-weight: bold;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      color: #fff;
      font-size: .25rem;
      padding-top: .125rem;
      padding-bottom: .25rem;
      border-bottom: .0125rem #fff solid;
      box-sizing: border-box;

      span {
        width: 16%;
        margin-top: .125rem;
      }

      .span {
        width: 28%;
      }

      .span1 {
        width: 46%;
      }
    }

    .imgs {
      display: flex;
      justify-content: space-between;
      width: 4.0125rem;
      height: 1.575rem;
      margin-top: .25rem;
      margin-left: 2.875rem;

      .img {
        width: 1.575rem;
        height: 1.575rem;
        background-color: #000;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      margin-top: .25rem;
      color: #fff;

      .info-title {
        margin-bottom: .125rem;
        font-size: .25rem;
      }

      span {
        margin-top: .075rem;
      }
    }
  }
}
</style>
