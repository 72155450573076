<template>
  <div class="tab-content">
    <div class="tip">
      <span>暂无数据</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      switchIndex: 1,
      switchs: Object.freeze(['基本信息', '材料证明'])
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .tip {
    color: #01EEFD;
    font-size: 18px;
  }
}
</style>
