<template>
  <div class="page-content">
    <div class="head">
      <el-input placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="page-box">
      <div class="page-box-left">
        <div class="page-box-title">
          <div class="title-position"></div>
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="text">详情</div>
          </div>
        </div>
        <div class="page-box-info">
          <div class="info-tab">
            <span class="info-tab-item" :class="{ 'info-tab-active': tabIndex === index }" v-for="(item, index) in heads"
              :key="index" @click="tabClick(index)">{{ item }}</span>
          </div>
          <div class="info-view">
            <Tab1 v-if="tabIndex === 0"></Tab1>
            <Tab2 v-else-if="tabIndex === 1"></Tab2>
            <Tab3 v-else-if="tabIndex === 2"></Tab3>
            <Tab4 v-else-if="tabIndex === 3"></Tab4>
          </div>
          <el-button class="return">返回</el-button>
        </div>
      </div>
      <div class="page-box-right">
        <div class="page-box-title">
          <div class="title-position"></div>
          <div class="title-top"></div>
          <div class="title-bottom">
            <div class="text">处理进度</div>
          </div>
        </div>
        <div class="page-box-info">
          <el-timeline>
            <el-timeline-item timestamp="【刘一】发起待遇申报申请" placement="top">
              <el-card>
                <div>刘一</div>
                <div>2018-08-07 12:00:00】发起待遇申报申请</div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="【刘一】发起待遇申报申请" placement="top">
              <el-card>
                <div>刘一</div>
                <div>2018-08-07 12:00:00】发起待遇申报申请</div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="【刘一】发起待遇申报申请" placement="top">
              <el-card>
                <div>刘一</div>
                <div>2018-08-07 12:00:00】发起待遇申报申请</div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="【刘一】发起待遇申报申请" placement="top">
              <el-card>
                <div>刘一</div>
                <div>2018-08-07 12:00:00】发起待遇申报申请</div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="【刘一】发起待遇申报申请" placement="top">
              <el-card>
                <div>刘一</div>
                <div>2018-08-07 12:00:00】发起待遇申报申请</div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="【刘一】发起待遇申报申请" placement="top">
              <el-card>
                <div>刘一</div>
                <div>2018-08-07 12:00:00】发起待遇申报申请</div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab1 from './tab1.vue'
import Tab2 from './tab2.vue'
import Tab3 from './tab3.vue'
import Tab4 from './tab4.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4
  },
  data () {
    return {
      heads: ['待遇申报信息', '病情检查信息 ', '专家鉴定信息', '医保终身信息'],
      tabIndex: 0
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    swith () {
      this.tabIndex = 0
    }
  }
}
</script>

<style lang="less" scoped>
.page-content {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 10px 37px;
  background: #081C38;
  border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 50px;

    .el-input {
      width: 246px;
      height: 38px;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }

      /deep/ .el-input__suffix {
        color: #fff;
        font-size: 18px;
      }

      /deep/ .el-icon-search {
        display: flex;
        align-items: center;
      }
    }
  }

  .page-box {
    display: flex;
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 10px;

    .page-box-title {
      overflow: hidden;
      position: relative;
      width: 148px;
      height: 54px;

      .title-position {
        position: absolute;
        top: -55px;
        left: -16px;
        z-index: 88;
        width: 0;
        height: 0;
        border-width: 40px;
        border-color: transparent transparent transparent #081C38;
        border-style: solid;
        transform: skewX(25deg);
      }

      .title-top {
        width: 87px;
        height: 14px;
        background-color: #03BCD7;
        transform: skewX(30deg);
      }

      .title-bottom {
        width: calc(100% - 20px);
        height: 40px;
        line-height: 40px;
        margin: 0 auto;
        text-align: center;
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        transform: skewX(30deg);

        .text {
          transform: skewX(-30deg);
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .page-box-left {
      width: calc(100% - 660px);
      height: 100%;
      margin-right: 10px;

      .page-box-info {
        width: 100%;
        height: calc(100% - 54px);
        padding: 0 10px;
        border: 1px solid #1F72D3;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        box-sizing: border-box;

        .info-tab {
          display: flex;
          align-items: center;
          width: 100%;
          height: 60px;
          border-bottom: 1px solid #03BCD7;
          box-sizing: border-box;

          .info-tab-item {
            height: 40px;
            line-height: 40px;
            margin-right: 16px;
            padding: 0 19px;
            background-color: #334253;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
          }

          .info-tab-active {
            background-color: #03BCD7;
          }
        }

        .info-view {
          width: 100%;
          height: calc(100% - 120px);
          margin-top: 10px;
        }

        .return {
          height: 30px;
          line-height: 30px;
          margin-top: 10px;
          padding-top: 0;
          padding-bottom: 0;
          background-color: #33465C;
          border: none;
          border-radius: 0;
          color: #fff;
          font-size: 14px;
        }
      }
    }

    .page-box-right {
      width: 650px;
      height: 100%;

      .page-box-info {
        width: 100%;
        height: calc(100% - 54px);
        padding: 40px;
        border: 1px solid #1F72D3;
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        box-sizing: border-box;

        .el-timeline {
          overflow-y: scroll;
          width: 100%;
          height: 100%;
          margin: 0 auto;

          &::-webkit-scrollbar {
            width: 0;
          }
        }

        /deep/.el-timeline-item__timestamp {
          color: #fff;
        }

        /deep/.el-timeline-item__node--normal {
          width: 22px;
          height: 22px;
          left: 0;
          border-radius: 50%;
          background-color: #00DFFF;
          border: 2px solid #DDFBFF;
          box-sizing: border-box;
        }

        /deep/.el-timeline-item__tail {
          border-color: #00DFFF;
          left: 10px;
        }

        .el-card {
          height: 77px;
          padding: 0;
          background-color: transparent;
          border: none;
          box-shadow: none;
          color: #FFFFFF;
          font-size: 14px;

          /deep/.el-card__body {
            padding: 0;
            padding: 0 10px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
</style>
